import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import types from "@/store/types";
import store from "@/store";
import { HashUtil } from "@/util/hash";

import VodRoutes from "./routes/vod";
import NolineRoutes from "./routes/noline";
import CaviRoutes from "./routes/cavi";

import OrganizationsShops from "@/views/organizations/shops.vue";

import Shops from "@/views/shops/Index.vue";
import ShopsTop from "@/views/shops/Top.vue";
import ShopsCoupons from "@/views/shops/Coupons.vue";
import ShopsMaps from "@/views/shops/Maps.vue";

import ShopToilet from "@/views/shop/toilet.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/organizations/:organizationIdHash/shops",
        component: OrganizationsShops,
        props: (route: Route) => ({ organizationIdHash: route.params.organizationIdHash }),
        beforeEnter: (to: Route, from: Route, next: (route?: string | Route) => void) => {
            if (to.params.organizationIdHash === "odB3xO5r") {
                window.location.replace("https://pages.vacan.com/09b1fcaa995745");
            } else if (to.params.organizationIdHash === "E4BDNVY8") {
                window.location.replace("https://pages.vacan.com/952c7457e1684e");
            } else if (to.params.organizationIdHash === "dbVbjnVZ") {
                window.location.replace("https://pages.vacan.com/0e82b14442c74f");
            } else if (to.params.organizationIdHash === "dpKkA5OJ") {
                window.location.replace("https://pages.vacan.com/0cdb1505a4764e");
            } else if (to.params.organizationIdHash === "E1OpAVv2") {
                window.location.replace("https://pages.vacan.com/03f2e3eb1ceb42");
            } else if (to.params.organizationIdHash === "aWV73VE5") {
                window.location.replace("https://pages.vacan.com/e549579d040343");
            } else if (to.params.organizationIdHash === "XvOeyOkQ") {
                window.location.replace("https://pages.vacan.com/498d74f89a804c");
            } else if (to.params.organizationIdHash === "ZMBMvGO7") {
                window.location.replace("https://pages.vacan.com/ccca76db1c3c44");
            } else if (to.params.organizationIdHash === "3vBY2OER") {
                window.location.replace("https://pages.vacan.com/baf096ff03694b");
            } else if (to.params.organizationIdHash === "vDVP7pO9") {
                window.location.replace("https://pages.vacan.com/8ae3a37e555d4c");
            } else if (to.params.organizationIdHash === "pXBaxxK3") {
                window.location.replace("https://vacan.com/building/A5ML3rOL");
            } else if (to.params.organizationIdHash === "WvOg5KRe") {
                window.location.replace("https://pages.vacan.com/6d088556b13f44");
            } else if (to.params.organizationIdHash === "A0VJyqOM") {
                window.location.replace("https://pages.vacan.com/0823422cef5d48");
            } else if (to.params.organizationIdHash === "NbOq92OM") {
                window.location.replace("https://pages.vacan.com/994e968305c846");
            } else if (to.params.organizationIdHash === "dbVbnVZX") {
                window.location.replace("https://pages.vacan.com/746c64c3d7b04c");
            }
            next();
        },
    },

    {
        // For current bookmarks
        path: "/organizations/:organizationIdHash/areas/:areaIdHash/shops",
        redirect: "/organizations/:organizationIdHash/shops",
    },

    {
        path: "/",
        // component: Top,
        beforeEnter: async (to: Route, from: Route, next: (route?: string) => void) => {
            window.location.replace("https://corp.vacan.com");
        },
    },
    {
        path: "/shops/:shopIdHash",
        component: Shops,
        redirect: "/shops/:shopIdHash/coupons",
        children: [
            { path: "", component: ShopsTop },
            { path: "coupons", component: ShopsCoupons },
            { path: "maps", component: ShopsMaps },
        ],
        beforeEnter: async (to: Route, from: Route, next: (route?: string) => void) => {
            const granstaShops = [
                { id: "j36gpW8l", target: "Q0XQVZ95", campaign: "Daisytokyo" },
                { id: "lBqko3qa", target: "3nDVRj9v", campaign: "BURDIGALA%20TOKYO" },
                { id: "7O6GBkqV", target: "LpXG36XM", campaign: "BOULANGERIE%20ASANOYA" },
                { id: "x51lEzqr", target: "Zk9o86rg", campaign: "Fairycake%20Fair" },
                { id: "V56PDK6g", target: "oGn35y9x", campaign: "THE%20STANDARD%20BAKERS%20TOKYO" },
                { id: "Wpqvm91n", target: "8oXO6GnM", campaign: "Made%20in%20PIERRE%20HERME" },
                { id: "4R8bYw6y", target: "G7nmjgXW", campaign: "MERUHEN" },
                { id: "j36gzBql", target: "g9MPNaXd", campaign: "5%20CROSSTIES%20COFFEE" },
                { id: "naqazQ63", target: "grjkLpnq", campaign: "HARADA" },
            ];

            const redirectTarget = granstaShops.find((granstaShop) => granstaShop.id === to.params.shopIdHash);
            if (redirectTarget) {
                window.location.replace(
                    `https://pages.vacan.com/952c7457e1684e/places/${redirectTarget.target}?utm_source=web&utm_medium=app&utm_campaign=${redirectTarget.campaign}`,
                );
                return;
            }

            const hashUtil: HashUtil = new HashUtil("shop");
            const shopId: number = hashUtil.idFromHash(to.params["shopIdHash"]);
            await store.dispatch(`shop/${types.shop.actions.SETUP}`, shopId);

            const organization = store.state.shop.organization;
            const shop = store.state.shop.shop;
            if (
                organization !== undefined &&
                shop !== undefined &&
                organization.organizationId === 3 &&
                !!shop.placeId
            ) {
                const placeIdHash = new HashUtil("place").hashFromId(shop.placeId);
                window.location.replace(`https://pages.vacan.com/03f2e3eb1ceb42/places/${placeIdHash}`);
                return;
            } else if (
                organization !== undefined &&
                shop !== undefined &&
                organization.organizationId === 33 &&
                !!shop.placeId
            ) {
                const placeIdHash = new HashUtil("place").hashFromId(shop.placeId);
                window.location.replace(`https://pages.vacan.com/e549579d040343/places/${placeIdHash}`);
                return;
            } else if (
                organization !== undefined &&
                shop !== undefined &&
                organization.organizationId === 34 &&
                !!shop.placeId
            ) {
                const placeIdHash = new HashUtil("place").hashFromId(shop.placeId);
                window.location.replace(`https://pages.vacan.com/498d74f89a804c/places/${placeIdHash}`);
                return;
            } else if (
                organization !== undefined &&
                shop !== undefined &&
                organization.organizationId === 49 &&
                !!shop.placeId
            ) {
                const placeIdHash = new HashUtil("place").hashFromId(shop.placeId);
                window.location.replace(`https://pages.vacan.com/ccca76db1c3c44/places/${placeIdHash}`);
                return;
            }

            next();
        },
    },
    {
        path: "/shop/toilet/:shopIdHash/:monitorIdHash",
        component: ShopToilet,
        alias: "/s/t/:shopIdHash/:monitorIdHash",
    },
    { path: "*", redirect: "/" },
];

const router: VueRouter = new VueRouter({
    mode: "history",
    routes: [...VodRoutes, ...NolineRoutes, ...CaviRoutes, ...routes],
});

export default router;
